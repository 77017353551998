import { InputGroup, InputGroupText } from "reactstrap";
import { TextInput } from "../../components/Input";

function PrependInputField({ prePendText = "", ...props }) {
    return (
        <>
            <InputGroup className="input-group-custom">
                <InputGroupText className="prepend-text">{prePendText}</InputGroupText>
                <TextInput containerClasses={"main-con-input"} {...props} />
            </InputGroup>
        </>
    );
}

export default PrependInputField;
