import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { NavLink as RouterNavLink, Route, Switch, Redirect, useHistory } from "react-router-dom";

import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";

const RoutingTabs = ({
    config = null,
    isTabs = true,
    isPills = false,
    isFill = false,
    redirectionFromRoute = ""
}) => {
    let history = useHistory();
    let currentPath = history?.location?.pathname;

    const [activeTab, setActiveTab] = useState({});
    // config.tabsConfig.find((item) => item.defaultActive)

    // :TODO:
    useEffect(() => {
        if (currentPath == APP_ROUTES.DASHBOARD) {
            setActiveTab(config.tabsConfig.find((item) => item.defaultActive));
        } else {
            let forActive = config.tabsConfig.find((item) => item.path == currentPath);
            setActiveTab(forActive);
        }
    }, [currentPath, config]);

    const handleSetActiveTab = (id) => setActiveTab(id);

    const getRedirect = () => {
        if (!activeTab.id) return null;

        return (
            <Redirect
                exact
                from={getParamsAttachedRoute(redirectionFromRoute, config?.param)}
                to={getParamsAttachedRoute(activeTab.path, config?.param)}
            />
        );
    };

    return (
        <div className="dashboard-tabs">
            <Nav tabs={isTabs} pills={isPills} fill={isFill}>
                {config.tabsConfig.map((item) => (
                    <NavItem key={item.id}>
                        <NavLink
                            tag={RouterNavLink}
                            to={getParamsAttachedRoute(item.path, config?.param)}
                            onClick={() => {
                                handleSetActiveTab(item);
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab.id}>
                <Switch>
                    {config.tabsConfig.map((item, ind) => (
                        <Route path={item.path} key={ind}>
                            <TabPane key={ind} tabId={item.id}>
                                {item.component}
                            </TabPane>
                        </Route>
                    ))}
                    {getRedirect()}
                </Switch>
            </TabContent>
        </div>
    );
};

export default RoutingTabs;
