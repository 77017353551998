import React from "react";
import { createRoot } from "react-dom/client";

import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";

import { Loader } from "./components/Loader";
import { store, persistor } from "./store";
import App from "./App";

const app = (
    <Provider store={store}>
        <PersistGate loading={<Loader size="sm" />} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(app);
