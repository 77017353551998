import React from "react";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const Filter = ({
    startDateFormat = "dd MMM , yyyy",
    endDateFormat = "dd MMM , yyyy",
    startDate = "",
    endDate = "",
    onChange = () => {}
}) => {
    return (
        <div className="d-flex">
            <DatePicker
                // isClearable
                className="date-picker-input me-2"
                placeholderText="Start Date"
                name="startDate"
                selected={startDate}
                onChange={(date) => onChange("startDate", date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat={startDateFormat}
                autoComplete="off"
            />
            <DatePicker
                // isClearable
                className="date-picker-input me-2"
                placeholderText="End Date"
                name="endDate"
                selected={endDate}
                onChange={(date) => onChange("endDate", date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={endDateFormat}
                autoComplete="off"
            />
        </div>
    );
};

export default Filter;
