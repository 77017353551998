import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";

import { APP_ROUTES } from "../../helpers/routeHelpers";
import { TopHeader } from "../../components/TopHeader";
import { CustomButton } from "../../components/CustomButton";
import { CustomModal } from "../../components/CustomModal";
import { Footer } from "../../components/Footer";
import { Login } from "../Authentication";
import Payment from "../../components/Payment/Payment";

const Home = () => {
    const { isAuthenticated } = useSelector((state) => state.userAuth);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const handleLoginModalClose = () => {
        setIsLoginModalOpen(false);
    };
    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <div className={`d-flex align-items-stretch min-vh-10 home-page-con`}>
                <div className="auth-wrap-con  col-12">
                    <Container>
                        <TopHeader />
                        <div>
                            <div className="header-login-btn-con d-flex justify-content-end align-items-center">
                                <CustomButton
                                    title={"Login"}
                                    className="fw-bold w-25 login-btn-bg"
                                    color="primary"
                                    outline
                                    onClick={() => setIsLoginModalOpen(true)}
                                />
                            </div>
                            <div className="home-body py-5 px-0 px-md-5 bg-white mt-3">
                                <div className={` d-flex align-items-stretch `}>
                                    <div className="col-12 d-flex align-items-center">
                                        <Payment />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </Container>
                </div>
            </div>
            <CustomModal
                isOpen={isLoginModalOpen}
                toggle={handleLoginModalClose}
                size="md"
                backdrop
            >
                <Login closeModalHandler={handleLoginModalClose} />
            </CustomModal>
        </>
    );
};

export default Home;
