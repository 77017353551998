import * as Yup from "yup";
import { useFormik } from "formik";

import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import KeyOutline from "../../assets/images/app/refund/key_outline.svg";
import CurrencyDollar from "../../assets/images/app/refund/currency-dollar.svg";
import ReceiptOutline from "../../assets/images/app/refund/receipt-outline.svg";
import { validateDecimalLimit } from "../../utils/commonUtils";

const RefundModal = ({ onSubmit = () => {}, transactionData = {}, isLoading = false }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            invoiceNumber: transactionData.invoiceNumber,
            amount: transactionData.amount,
            approvalCode: ""
        },
        validationSchema: Yup.object({
            approvalCode: Yup.string().required("Enter approval code"),
            amount: Yup.number("Enter amount")

                .positive("Amount can't have any special characters, negative or 0 values")
                .test(
                    "is-in-range",
                    "Amount can't be 0 or greater than the actual payment amount !",
                    (value) => {
                        return !(value <= 0) && !(value > transactionData.amount);
                    }
                )
                .test(
                    "deciman-units",
                    "Can't add more the 2 none-zero decimal digits!",
                    (value) => {
                        if (value.toString().indexOf(".") == -1) return true;
                        return validateDecimalLimit(value, 2, 1);
                    }
                )

                .required("Enter valid amount")
        }),
        onSubmit: (values) => {
            onSubmit(values);
        }
    });

    return (
        <>
            <div className="px-5 mx-5">
                <h1 className="auth-heading m-0 p-0 text-center">Refund</h1>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <TextInput
                            placeholder="Invoice Number"
                            name={"invoiceNumber"}
                            type="text"
                            className="hide-default-icon"
                            value={formik.values.invoiceNumber}
                            disabled
                            iconBeforeText={ReceiptOutline}
                        />
                    </div>

                    <div className="mb-4">
                        <TextInput
                            placeholder="$00.00"
                            type={"number"}
                            className="hide-default-icon"
                            name="amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amount}
                            iconBeforeText={CurrencyDollar}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                            <span className="error">{formik.errors.amount}</span>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Provide Approval Code"
                            name={"approvalCode"}
                            type="text"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.approvalCode}
                            iconBeforeText={KeyOutline}
                        />
                        {formik.touched.approvalCode && formik.errors.approvalCode ? (
                            <span className="error">{formik.errors.approvalCode}</span>
                        ) : null}
                    </div>

                    <CustomButton
                        loading={isLoading}
                        type="submit"
                        color="primary"
                        title="Proceed"
                        className="w-100 mt-4"
                    />
                </form>
            </div>
        </>
    );
};

export default RefundModal;
