import React, { useState } from "react";
import moment from "moment";

import { DataTable } from "../../components/DataTable";
import { CustomModal } from "../../components/CustomModal";
import { Pagination } from "../../components/Pagination";
import { CustomButton } from "../../components/CustomButton";
import { PageTitle } from "../../components/PageTitle";
import { RefundModal } from "../Modals";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { GetTransactions, ProcessRefund } from "../../api/api.service";
import { PageTitles, SortOrder, TransactionsStatus } from "../../constants";
import { converDateTimeUtcToLocal, dateTimeFormat } from "../../utils/dateFormatUtils";
import { showToast } from "../../utils/toastUtils";
import Config from "../../Config";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { Col, Row } from "reactstrap";

const COLUMN = {
    INVOICE_NUMBER: "INVOICE NUMBER",
    EMAIL_ADDRESS: "EMAIL ADDRESS",
    DATE: "DATE / TIME ",
    REFUND_AFTER: "Refundable After",
    AMOUNT: "AMOUNT",
    STATUS: "STATUS",
    ACTION: "ACTION"
};

const Refund = ({}) => {
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [targetedTransaction, setTargetedTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {
        limit,
        isFetching,
        data,
        page,
        total,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSetData,
        handlePageLimit,
        request
    } = useLocalPaginatedRequest(
        GetTransactions,
        {
            status: [TransactionsStatus.Paid],
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder })
        },
        [currentSortOrder, currentSortColumn],
        "Q",
        "data",
        "total"
    );
    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleRefundModalClose = () => {
        setIsRefundModalOpen(false);
    };

    const handleInitRefund = (data) => {
        setIsRefundModalOpen(true);
        setTargetedTransaction(data);
    };

    const hasDatePassedOrSame = (date) => {
        const utcTime = date;
        const userTimeZoneOffset = moment().utcOffset();
        const localMoment = moment.utc(utcTime).utcOffset(userTimeZoneOffset);
        const currentLocalTime = moment();
        const hasPassed = localMoment.isSameOrBefore(currentLocalTime);
        return hasPassed;
    };

    const isRestrictRefund = (data) => {
        let isDatePassedOrSame = hasDatePassedOrSame(data.refundEligibilityDate);
        return !isDatePassedOrSame || data.status !== TransactionsStatus.Paid;
    };

    const handleRefund = async (obj) => {
        let bodyData = {
            invoiceNumber: obj.invoiceNumber,
            amount: obj.amount,
            code: obj.approvalCode
        };

        try {
            setIsLoading(true);
            let res = await ProcessRefund(bodyData);
            handleRefundModalClose();
            showToast("success", res.message);
            request();
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div>
                <PageTitle title={PageTitles.REFUND} />
                <div className="home-body p-3 bg-white mt-3 refund-main-con">
                    <Row className="py-2">
                        <Col sm="12" md="4" lg="4">
                            <ListingHeader
                                onSearchChange={handleSearchChange}
                                searchedText={searchText}
                            />
                        </Col>
                    </Row>

                    <DataTable
                        tableContainerClass=""
                        loading={isFetching}
                        data={data}
                        currentSortOrder={currentSortOrder}
                        currentSortColumn={currentSortColumn}
                        onSortClick={handleSortClick}
                        config={[
                            {
                                title: COLUMN.INVOICE_NUMBER,
                                className: "bold-font sa-cust-name text-truncate ",
                                render: (data) => data?.invoiceNumber || "-"
                            },
                            {
                                title: COLUMN.EMAIL_ADDRESS,
                                className: "sa-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: COLUMN.DATE,
                                className: "sa-region",
                                render: (data) =>
                                    converDateTimeUtcToLocal(
                                        data?.createdAt,
                                        dateTimeFormat.DD_SPACE_MMM_YYYY_HH_MM_AM_PM
                                    ) || "-"
                            },
                            {
                                title: COLUMN.REFUND_AFTER,
                                className: "sa-region",
                                render: (data) =>
                                    converDateTimeUtcToLocal(
                                        data?.refundEligibilityDate,
                                        dateTimeFormat.DD_SPACE_MMM_YYYY_HH_MM_AM_PM
                                    ) || "-"
                            },
                            {
                                title: COLUMN.AMOUNT,
                                className: "bold-font sa-process ",
                                render: (data) => `$${data?.amount?.toFixed(2)}` || "-"
                            },
                            {
                                title: COLUMN.STATUS,
                                className: "bold-font sa-process ",
                                render: (data) => (
                                    <span
                                        className={`${TransactionsStatus.getLabelClass(
                                            data?.status
                                        )}`}
                                    >
                                        {TransactionsStatus.getDisplayTextKey(data?.status)}
                                    </span>
                                )
                            },

                            {
                                title: COLUMN.ACTION,
                                className: "sa-action",
                                render: (data) => (
                                    <CustomButton
                                        disabled={isRestrictRefund(data)}
                                        title="Refund"
                                        className=" pointer ms-3 refund-table-action"
                                        onClick={() => handleInitRefund(data)}
                                    />
                                )
                            }
                        ]}
                    />

                    <Pagination
                        onPageClick={handlePageClick}
                        currentPage={page}
                        totalData={total}
                        rowLimit={limit}
                        count={data?.length}
                        handleChange={(e) => handlePageLimit(e.target.value)}
                    />
                </div>
            </div>
            <CustomModal
                isOpen={isRefundModalOpen}
                toggle={handleRefundModalClose}
                size="md"
                backdrop
            >
                <RefundModal
                    onSubmit={handleRefund}
                    transactionData={targetedTransaction}
                    isLoading={isLoading}
                />
            </CustomModal>
        </>
    );
};

export default Refund;
