var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "PRODUCTION",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "",
            GOOGLE_API_KEY: "",
            AUTHORIZE_NET_URL: ""
        },
        DEVELOPMENT: {
            API_URL: "https://test-leapcart.kodefuse.com/v1",
            GOOGLE_API_KEY: "",
            AUTHORIZE_NET_URL: "https://test.authorize.net/payment/payment"
        },
        STAGING: {
            API_URL: "https://test-leapcart.kodefuse.com/v1",
            GOOGLE_API_KEY: "",
            AUTHORIZE_NET_URL: "https://test.authorize.net/payment/payment"
        },
        PRODUCTION: {
            API_URL: "https://api.leapcart.com/v1",
            GOOGLE_API_KEY: "",
            AUTHORIZE_NET_URL: "https://accept.authorize.net/payment/payment"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;
