import { injectBaseConstantMethods } from "./BaseConstants";

const Roles = {
    Admin: "ADMIN",
    Manager: "USER"
};

const displayTextKeys = {
    [Roles.Admin]: "Admin",
    [Roles.Manager]: "Manager"
};

const labelClass = {
    [Roles.Admin]: "admin",
    [Roles.Manager]: "manager"
};

export default injectBaseConstantMethods(Roles, displayTextKeys, labelClass);
