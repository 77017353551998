import React from "react";
import { Spinner } from "reactstrap";

import "./Loader.scss";

const OverlayLoader = ({ isLoading = false }) => {
    return (
        <>
            {isLoading && (
                <div className="overlay-loading-container">
                    <Spinner />
                </div>
            )}
        </>
    );
};

export default OverlayLoader;
