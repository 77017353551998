import React from "react";
import { Row, Col } from "reactstrap";

import { SearchBox } from "../SearchBox";

const ListingHeader = ({ onSearchChange = () => {}, searchedText = "" }) => {
    return (
        <>
            <Row>
                <Col sm="12" md="12" lg="12">
                    <SearchBox className="me-3" value={searchedText} onChange={onSearchChange} />
                </Col>
            </Row>
        </>
    );
};

export default ListingHeader;
