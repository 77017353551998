import React, { useState } from "react";
import { DataTable } from "../../components/DataTable";

import { SortOrder, TransactionsStatus, PageTitles } from "../../constants";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { GetCSVReport, GetTransactions } from "../../api/api.service";
import { Pagination } from "../../components/Pagination";
import { PageTitle } from "../../components/PageTitle";
import { converDateTimeUtcToLocal, dateTimeFormat, getOffset } from "../../utils/dateFormatUtils";
import direction from "../../constants/SortOrder";
import { handleFilterChange } from "../../utils/tableUtils";
import { CustomButton } from "../../components/CustomButton";
import { downloadCSVData } from "../../utils/downloadUtils";
import { ListingFilterBar } from "../../components/ListingFilterBar";
import { getSelectBoxOptionsFromConstants } from "../../utils/commonUtils";
import ListingHeader from "../../components/DataTable/ListingHeader";
import moment from "moment";
import { Col, Row } from "reactstrap";

const COLUMNS = {
    INVOICE_NUMBER: "INVOICE NUMBER",
    EMAIL_ADDRESS: "EMAIL ADDRESS",
    CREATED_DATE: "CREATED DATE / TIME",
    UPDATED_DATE: "UPDATED DATE / TIME",
    AMOUNT: "AMOUNT",
    ACTION: "ACTION",
    REFUNDED_BY: "Refunded By",
    REFUNDED_AMOUNT: "Refunded Amount",
    STATUS: "STATUS"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.STATUS]: "paymentStatus"
};

const Transactions = ({}) => {
    let statusOptions = getSelectBoxOptionsFromConstants(TransactionsStatus);
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [status, setStatus] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [filters, setFilters] = useState({
        paymentStatus: ""
    });

    const {
        isFetching,
        data,
        page,
        total,
        limit,
        searchText,
        setPage,
        handlePageClick,
        handleSearchChange,
        handlePageLimit,
        handleSetData,
        request
    } = useLocalPaginatedRequest(
        GetTransactions,
        {
            direction: direction.DESC,
            column: "updatedAt",
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(status?.length && { status: status.map((item) => item.value) }),
            ...(dateRange?.startDate && {
                startDate: moment(dateRange.startDate).format("YYYY-MM-DDT00:00:00[Z]")
            }),
            ...(dateRange?.endDate && {
                endDate: moment(dateRange.endDate).format("YYYY-MM-DDT23:59:59[Z]")
            })
        },
        [currentSortOrder, currentSortColumn, status, dateRange],
        "Q",
        "data",
        "total"
    );
    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleDownloadCSV = async () => {
        const userOffset = getOffset();
        let payload = {
            offset: userOffset,
            ...(status?.length && { status: status.map((item) => item.value) }),
            ...(dateRange?.startDate && {
                startDate: moment(dateRange.startDate).format("YYYY-MM-DDT00:00:00[Z]")
            }),
            ...(dateRange?.endDate && {
                endDate: moment(dateRange.endDate).format("YYYY-MM-DDT23:59:59[Z]")
            })
        };
        try {
            setIsLoading(true);
            let res = await GetCSVReport({ bodyData: payload });
            await downloadCSVData(res);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateSelection = (name, date) => {
        setPage(1);
        setDateRange({ ...dateRange, [name]: date });
    };
    const handleStatusSelection = (value) => {
        setPage(1);
        setStatus(value);
    };

    return (
        <>
            <div>
                <PageTitle title={PageTitles.TRANSACTIONS} />
                <div className="home-body p-3 bg-white mt-3 transactions-main-con">
                    <Row className="py-2">
                        <Col sm="12" md="4" lg="4" className="py-2">
                            <ListingHeader
                                onSearchChange={handleSearchChange}
                                searchedText={searchText}
                            />
                        </Col>

                        <Col sm="12" md="8" lg="8" className="py-2">
                            <Row>
                                <Col sm="12" md="9" lg="9">
                                    <ListingFilterBar
                                        isMulti
                                        dropDownPlaceholder="Filter by status"
                                        dropDownValue={status}
                                        dropDownOptions={statusOptions}
                                        handleDropDownChange={handleStatusSelection}
                                        handleDateChange={handleDateSelection}
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                    />
                                </Col>
                                <Col sm="12" md="3" lg="3" align="right">
                                    <CustomButton
                                        type="submit"
                                        color="primary"
                                        title="Export CSV"
                                        className="export-csv-btn"
                                        loading={isLoading}
                                        onClick={handleDownloadCSV}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <DataTable
                        tableContainerClass=""
                        loading={isFetching}
                        data={data}
                        currentSortOrder={currentSortOrder}
                        currentSortColumn={currentSortColumn}
                        onSortClick={handleSortClick}
                        config={[
                            {
                                title: COLUMNS.INVOICE_NUMBER,
                                className: "bold-font sa-cust-name text-truncate ",
                                render: (data) => data?.invoiceNumber || "-"
                            },
                            {
                                title: COLUMNS.EMAIL_ADDRESS,
                                className: "sa-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: COLUMNS.CREATED_DATE,

                                className: "sa-region",
                                render: (data) =>
                                    converDateTimeUtcToLocal(
                                        data?.createdAt,
                                        dateTimeFormat.DD_SPACE_MMM_YYYY_HH_MM_AM_PM
                                    ) || "-"
                            },
                            {
                                title: COLUMNS.UPDATED_DATE,

                                className: "sa-region",
                                render: (data) =>
                                    converDateTimeUtcToLocal(
                                        data?.updatedAt,
                                        dateTimeFormat.DD_SPACE_MMM_YYYY_HH_MM_AM_PM
                                    ) || "-"
                            },
                            {
                                title: COLUMNS.AMOUNT,
                                className: "bold-font sa-process ",
                                render: (data) => `$${data?.amount?.toFixed(2)}` || "-"
                            },

                            {
                                title: COLUMNS.REFUNDED_BY,
                                className: "bold-font sa-process ",
                                render: (data) => data?.user?.name || "-"
                            },
                            {
                                title: COLUMNS.REFUNDED_AMOUNT,
                                className: "bold-font sa-process ",
                                render: (data) => `$${data?.refundAmount?.toFixed(2)}` || "-"
                            },

                            {
                                title: COLUMNS.STATUS,
                                className: `sa-action bold-600`,
                                render: (data) => (
                                    <>
                                        <span
                                            className={`${TransactionsStatus.getLabelClass(
                                                data?.status
                                            )}`}
                                        >
                                            {TransactionsStatus.getDisplayTextKey(data?.status)}
                                        </span>
                                    </>
                                )
                                // As no need right now

                                // isFilterable: true,
                                // filterConstants: TransactionsStatus,
                                // filterValue: filters.paymentStatus,
                                // onFilterSelect: (value) =>
                                //     handleFilterChange(
                                //         SORTABLE_COLUMNS[COLUMNS.STATUS],
                                //         value,
                                //         handlePageClick,
                                //         setFilters
                                //     )
                            }
                        ]}
                    />
                    <Pagination
                        count={data?.length}
                        currentPage={page}
                        totalData={total}
                        rowLimit={limit}
                        handleChange={(e) => handlePageLimit(e.target.value)}
                        onPageClick={handlePageClick}
                    />
                </div>
            </div>
        </>
    );
};

export default Transactions;
