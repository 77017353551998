const PageTitles = {
    LOGIN: "Login",
    FORGET_PASSWORD: "Forget Password",
    OTP_VERIFICATION: "OTP Verification",
    LOGOUT: "Logout",
    REGISTER: "Register",
    RESET_PASSWORD: "Reset Password",
    CHARGE: "Charge",
    REFUND: "Refund",
    TRANSACTIONS: "Transactions",
    MANAGERS: "Managers",
    ACCOUNT: "Account",
    HOME: "Home"
};

export default PageTitles;
