import { injectBaseConstantMethods } from "./BaseConstants";

const TransactionsStatus = {
    Pending: "PENDING",
    Paid: "PAID",
    Failed: "FAILED",
    Refunded: "REFUNDED",
    RefundInProgress: "REFUNDPROGRESS",
    PartialRefunded: "PARTIALREFUNDED"
};

const displayTextKeys = {
    [TransactionsStatus.Refunded]: "Fully Refunded",
    [TransactionsStatus.Paid]: "Captured",
    [TransactionsStatus.Pending]: "Pending",
    [TransactionsStatus.Failed]: "Failed",
    [TransactionsStatus.RefundInProgress]: "Refund In Progress",
    [TransactionsStatus.PartialRefunded]: "Partially Refunded"
};

const labelClass = {
    [TransactionsStatus.Refunded]: "refunded-status-color",
    [TransactionsStatus.Paid]: "captured-status-color",
    [TransactionsStatus.Pending]: "",
    [TransactionsStatus.Failed]: "failed-status-color",
    [TransactionsStatus.RefundInProgress]: "refund-in-progress-status-color",
    [TransactionsStatus.PartialRefunded]: "refunded-status-color"
};

export default injectBaseConstantMethods(TransactionsStatus, displayTextKeys, labelClass);
