import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authProtectedRoutes } from "../../routes";
import { Loader } from "../../components/Loader";
import { Page500 } from "../../components/Utility";
import { getMe } from "../../store/actions";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const DefaultLayout = () => {
    let dispatch = useDispatch();
    const { tokenVerified, isLoading } = useSelector((state) => state.root);
    const { isAuthenticated } = useSelector((state) => state.userAuth);

    const Role = useSelector((state) => state?.userAuth?.user?.type);

    useEffect(() => {
        if (isAuthenticated) {
            if (!tokenVerified) {
                dispatch(getMe()).catch(() => {});
            }
        }
    }, []);

    if (!isAuthenticated) {
        return <Redirect to={APP_ROUTES.HOME} />;
    }

    const roleBasedRoutes = authProtectedRoutes.filter((item) => item.permissions?.includes(Role));

    return tokenVerified ? (
        <Switch>
            {roleBasedRoutes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                    />
                );
            })}

            <Redirect to={APP_ROUTES.DASHBOARD} />
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader classes="vh-100" showVerifyText={true} />
    ) : // <Page500 />
    null;
};

export default DefaultLayout;
