import React from "react";

import Payment from "../../components/Payment/Payment";
import { PageTitles } from "../../constants";
import { PageTitle } from "../../components/PageTitle";

const Charge = ({}) => {
    return (
        <>
            <div>
                <PageTitle title={PageTitles.CHARGE} />
                <div className="home-body py-5 px-0 px-md-5 bg-white mt-3">
                    <div className={` d-flex align-items-stretch `}>
                        <div className="col-12 d-flex align-items-center">
                            <Payment />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Charge;
