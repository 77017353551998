export const downloadCSVData = (csvText) => {
    let blob = new Blob([csvText]);
    let link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURI(csvText);
    link.href = window.URL.createObjectURL(blob);
    link.download = `${new Date().getTime()}.csv`;
    link.click();

    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.style.display = "none";
    // a.href = url;
    // // the filename you want
    // a.download = `sample_${new Date().getTime()}.csv`;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // window.URL.revokeObjectURL(url);
};

export const downloadXlsData = async (response) => {
    let blob = await response.blob();
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `template_${new Date().getTime()}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
};
