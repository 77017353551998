import React from "react";
import { forwardRef } from "react";
import { Input } from "reactstrap";

const TextInput = forwardRef(
    (
        {
            className = "hide-default-icon",
            containerClasses = "",
            inputClasses = "",
            name,
            placeholder,
            type = "text",
            onChange,
            value,
            children,
            autoComplete = "off",
            icon = null,
            label = "",
            size = "",
            iconBeforeText = null,
            iconClickHandler = () => {},
            isFormattedNumber = false,

            ...rest
        },
        ref
    ) => {
        return (
            <div className={`d-flex flex-column ${containerClasses}`}>
                {label && <p className="input-label fw-bold mb-2">{label}</p>}

                <div className={`custom-text-input ${icon && "hide-default-icon"} ${className} `}>
                    <Input
                        {...rest}
                        ref={ref}
                        bsSize={size}
                        name={name}
                        className={`form-control ${inputClasses}`}
                        placeholder={placeholder}
                        type={type}
                        onChange={onChange}
                        value={value}
                        autoComplete={autoComplete}
                    >
                        {children}
                    </Input>
                    {iconBeforeText && (
                        <img src={iconBeforeText} className="before-text-icon" alt="icon" />
                    )}
                    {icon && (
                        <span
                            onClick={iconClickHandler}
                            className={`${icon} input-icon cursor-pointer p-2`}
                            src={icon}
                            alt="icon"
                        />
                    )}
                    {children}
                </div>
            </div>
        );
    }
);

export default TextInput;
