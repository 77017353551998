import { Link } from "react-router-dom";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import Logo from "../../assets/images/app/common/logo@2x.png";

function TopHeader({ isProfileMenu = false }) {
    return (
        <>
            <header
                className="gt-topbar 
            
            "
            >
                <div className="navbar-header-custom px-0">
                    <div className="d-flex">
                        <div className="navbar-brand-box ">
                            <Link to="/home" className="logo">
                                <span className="logo-lg">
                                    <img src={Logo} height={77} alt="logo" />
                                </span>
                            </Link>
                        </div>
                    </div>
                    {isProfileMenu ? (
                        <div className="d-flex">
                            <ProfileMenu />
                        </div>
                    ) : null}
                </div>
            </header>
        </>
    );
}

export default TopHeader;
