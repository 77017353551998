import { injectBaseConstantMethods } from "./BaseConstants";

const ManagerStatus = {
    Active: "ACTIVE",
    Suspend: "INACTIVE"
};

const displayTextKeys = {
    [ManagerStatus.Active]: "Active",
    [ManagerStatus.Suspend]: "Suspend"
};

const labelClass = {
    [ManagerStatus.Active]: "manager-active",
    [ManagerStatus.Suspend]: "manager-suspended"
};

export default injectBaseConstantMethods(ManagerStatus, displayTextKeys, labelClass);
