function Footer() {
    return null;
    return (
        <>
            <div className="footer-con my-4 d-flex justify-content-between align-items-center">
                <div>
                    <p>© Copyright 2023 Leapcart. All Rights Reserved.</p>
                </div>
                <div>
                    <a href="#">Privacy Policy</a>
                    <a href="#" className="ms-5">
                        Acceptable Use Policy
                    </a>
                </div>
            </div>
        </>
    );
}

export default Footer;
