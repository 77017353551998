import React from "react";
import APP_CONFIG from "../../APP_CONFIG";
import { Helmet } from "react-helmet";

const PageTitle = ({ title = "" }) => {
    return (
        <Helmet>
            <title>{`${title} - ${APP_CONFIG.APP_NAME}`}</title>
        </Helmet>
    );
};

export default PageTitle;
