import { useState, useEffect, useRef } from "react";

import direction from "../constants/SortOrder";
import Config from "../Config";

export const useLocalPaginatedRequest = (
    requestFn = () => Promise.resolve(),
    params = {},
    deps = [],
    searchKey = "Q",
    dataKey = "Data",
    totalKey = "TotalCount",
    dataId = null
) => {
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState("");
    const [total, setTotal] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [limit, setLimit] = useState(Config.LIMIT);

    const requestAbortController = useRef(null);

    useEffect(() => {
        requestAbortController.current = new AbortController();

        request();

        return () => {
            if (requestAbortController.current) {
                requestAbortController.current.abort();
            }
        };
        // eslint-disable-next-line
    }, [page, searchText, limit, ...deps]);

    const request = () => {
        setIsFetching(true);
        requestFn(
            {
                limit: limit,
                page: page,
                direction: direction.DESC,
                column: "createdAt",
                ...(searchText && { [searchKey]: searchText }),
                ...(Object.keys(params).length && params)
            },
            requestAbortController.current.signal,
            dataId
        )
            .then((res) => {
                setData(res[dataKey]);
                setTotal(res[totalKey]);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const handleSearchChange = (e) => {
        let searchText = e.target.value;
        setSearchText(searchText);
        setPage(1);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };
    const handlePageLimit = (limit) => {
        setPage(1);
        setLimit(limit);
    };

    const handleSetData = (newData) => setData(newData);

    return {
        limit,
        page,
        searchText,
        data,
        total,
        isFetching,
        setPage,
        request,
        handleSearchChange,
        handlePageClick,
        handleSetData,
        handlePageLimit
    };
};
