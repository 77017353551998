import * as Yup from "yup";

export const initialValues = {
    login: {
        email: "",
        password: ""
    },
    changePassword: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    }
};

export const loginFormSchema = Yup.object({
    email: Yup.string().email("Enter a valid email address").required("Enter email address"),
    password: Yup.string().required("Enter password")
});

export const changePasswordFormSchema = (isManager = false) => {
    return Yup.object({
        ...(!isManager && {
            oldPassword: Yup.string().required("Enter old password")
        }),
        newPassword: Yup.string().required("Enter new password"),
        confirmNewPassword: Yup.string()
            .required("Enter confirm password")
            .oneOf(
                [Yup.ref("newPassword"), null],
                "Password mismatched, new password and confirm password should be same !"
            )
    });
};
