import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import KeyOutline from "../../assets/images/app/refund/key_outline.svg";
import userIcon from "../../assets/images/app/auth-page/user-1_curved.svg";
import { ChangeApprovalCode } from "../../api/api.service";

function ChangeApprovalCodeModal({ closeModalHandler, hasManager = null }) {
    let currentUser = useSelector((state) => state.userAuth.user);
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            newApprovalCode: ""
        },
        validationSchema: Yup.object({
            newApprovalCode: Yup.string().required("Enter new approval code")
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { newApprovalCode } = values;
        let currentUserId = hasManager ? hasManager.id : currentUser.id;

        let bodyData = {
            code: newApprovalCode
        };
        try {
            setIsLoading(true);
            let res = await ChangeApprovalCode(currentUserId, bodyData);
            closeModalHandler();
            toast.success("Approval code has been changed successfully !");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="px-5 mx-5">
                <h1 className="auth-heading m-0 p-0 text-center">{"Change Approval Code"}</h1>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <TextInput
                            disabled
                            type="text"
                            className="hide-default-icon"
                            value={hasManager ? hasManager.name : currentUser.name}
                            iconBeforeText={userIcon}
                        />
                        {formik.touched.username && formik.errors.username ? (
                            <span className="error">{formik.errors.username}</span>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="New Approval Code"
                            name={"newApprovalCode"}
                            type="text"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newApprovalCode}
                            iconBeforeText={KeyOutline}
                        />
                        {formik.touched.newApprovalCode && formik.errors.newApprovalCode ? (
                            <span className="error">{formik.errors.newApprovalCode}</span>
                        ) : null}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="w-100 mt-4 me-3 back-btn" onClick={closeModalHandler}>
                            Cancel
                        </span>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title="Change"
                            className="w-100 mt-4 ms-3"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default ChangeApprovalCodeModal;
