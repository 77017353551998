import moment from "moment";

export const dateTimeFormat = {
    GMT_YYYY_MM_DD_HH_MM_DASH_SEPARATOR: "YYYY-MM-DD HH:mm",
    DD_SPACE_MMM_YYYY: "DD MMM, YYYY",
    DD_SPACE_MMM_YYYY_HH_MM_AM_PM: "DD MMM, YYYY / h:mm:ss a",
    DD_MM_YYYY_COMMA_SEPARATOR: "DD,MM,YYYY",
    DD_MM_YYYY_SLASH_SEPARATOR: "DD/MM/YYYY",
    MMMM_YYYY_COMMA_SEPARATOR: "MM,YYYY",
    MMMM_YYYY_SLASH_SEPARATOR: "MM/YYYY",
    DD_MM_YYYY_HH_MM_SLASH_SEPARATOR: "DD/MM/YYYY - HH:mm",
    HH_MM_SS: "HH:mm:ss",
    HH_MM: "HH:mm",
    H_MM: "H:mm",
    HH_MM_AM_PM: "HH:mm A"
};

export const convertDateTime = ({ date, dateOnly = false, customFormat = null }) => {
    if (!date) return "";
    const format = customFormat
        ? customFormat
        : dateOnly
        ? dateTimeFormat.DD_SPACE_MMM_YYYY
        : dateTimeFormat.DD_MM_YYYY_HH_MM_SLASH_SEPARATOR;

    return moment(date).format(format);
};

export const convertTime = ({ tz, time, format }) => {
    if (tz === "GMT_TIME") {
        return moment(time, format).utc().format(format);
    } else if (tz === "LOCAL_TIME") {
        return moment.utc(time, format).local().format(format);
    }
};

export const convertDateTimeGMTFormat = (date) => {
    const startOf = () => {
        return moment(date)
            .startOf("day")
            .format(dateTimeFormat.GMT_YYYY_MM_DD_HH_MM_DASH_SEPARATOR);
    };
    const endOf = () => {
        return moment(date).endOf("day").format(dateTimeFormat.GMT_YYYY_MM_DD_HH_MM_DASH_SEPARATOR);
    };
    return {
        startOf,
        endOf
    };
};

export const convertDateToInvoicePattern = (date) => {
    const getPreviousMonthDate = () => {
        return moment(date).subtract(1, "month").format("LL");
    };
    const getPreviousDayDate = () => {
        return moment(date).subtract(1, "day").format("LL");
    };
    return {
        getPreviousMonthDate,
        getPreviousDayDate
    };
};

export const getTimeWithSeconds = (time, format, requiredFormat) => {
    return moment(time, format).format(requiredFormat);
};

export const getNearestTimeStampStr = (date) => {
    let isDaySame = moment().isSame(date, "day");
    let isYearSame = moment().isSame(date, "year");
    if (isDaySame) {
        return moment(date).format("LT");
    } else if (!isDaySame && isYearSame) {
        return moment(date).format(dateTimeFormat.HH_MM_SS);
    } else return moment(date).format("MMM  Do YYYY, h:mm A");
};

export const converDateTimeUtcToLocal = (utcDateStr = "", formatStr = "") => {
    const utcTimeDate = utcDateStr;
    const userTimeZoneOffset = moment().utcOffset();
    const localTimeDate = moment.utc(utcTimeDate).utcOffset(userTimeZoneOffset).format(formatStr);
    return localTimeDate;
};

export const getOffset = (inHours = true) => {
    let offset = moment().utcOffset();

    if (inHours) {
        offset = offset / 60;
    }
    return offset;
};
