import { useState } from "react";
import { Container } from "reactstrap";
import { useFormik } from "formik";

import { CustomButton } from "../../components/CustomButton";
import { PrependInputField } from "../../components/PrependInputField";
import { initialValues, validationSchema } from "./FormConfig";
import { GetPaymentToken } from "../../api/api.service";
import { PaymentForm } from "../../components/PaymentForm";
import currencyIcon from "../../assets/images/app/common/currency-icon.svg";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const Payment = ({}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handlePaymentProcess(values);
        }
    });

    const handlePaymentProcess = async (values) => {
        let bodyData = {
            email: values.email,
            invoiceNumber: values.invoice,
            amount: values.amount
        };

        try {
            setIsLoading(true);
            let res = await GetPaymentToken(bodyData);
            setToken(res.token);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <div className="my-5 offset-1 col-10 offset-xl-3 col-xl-6 offset-md-2 col-md-8 py-5 px-0 px-sm-3 ">
                <form className="" onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="mb-4">
                        <PrependInputField
                            placeholder="Type invoice number"
                            prePendText={"Invoice No"}
                            name={"invoice"}
                            type="text"
                            className="hide-default-icon input-group-shadow"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.invoice}
                        />
                        <FormikErrorText fieldName={"invoice"} formikInstance={formik} />
                    </div>

                    <div className="mb-4">
                        <PrependInputField
                            prePendText={"Email"}
                            placeholder="Type email address"
                            type={"text"}
                            className="hide-default-icon input-group-shadow"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        <FormikErrorText fieldName={"email"} formikInstance={formik} />
                    </div>

                    <div className="mb-4">
                        <PrependInputField
                            prePendText={"Enter amount"}
                            placeholder="00.00"
                            type={"number"}
                            className="hide-default-icon input-group-shadow"
                            name="amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={parseFloat(formik.values.amount)}
                            iconBeforeText={currencyIcon}
                        />
                        <FormikErrorText fieldName={"amount"} formikInstance={formik} />
                    </div>

                    <CustomButton
                        loading={isLoading}
                        type="submit"
                        color="primary"
                        title="Next"
                        className="w-100 mt-4"
                    />
                </form>
            </div>
            <PaymentForm token={token} />
        </Container>
    );
};

export default Payment;
