import { Dashboard } from "../pages/Dashboard";
import { Managers } from "../pages/Managers";
import { Refund } from "../pages/Refund";
import { Transactions } from "../pages/Transactions";
import { Charge } from "../pages/Charge";
import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";

let { Manager, Admin } = Roles;

const authProtectedRoutes = [
    {
        path: APP_ROUTES.DASHBOARD,
        component: Dashboard,
        permissions: [Admin, Manager],
        exact: false
    },
    {
        path: APP_ROUTES.CHARGE,
        component: Charge,
        permissions: [Admin, Manager],
        exact: true
    },
    {
        path: APP_ROUTES.REFUND,
        component: Refund,
        permissions: [Admin, Manager],
        exact: true
    },
    {
        path: APP_ROUTES.TRANSACTIONS,
        component: Transactions,
        permissions: [Admin, Manager],
        exact: true
    },
    {
        path: APP_ROUTES.MANAGERS,
        component: Managers,
        permissions: [Admin],
        exact: true
    }
];

export { authProtectedRoutes };
