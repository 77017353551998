import toast from "react-hot-toast";

const SUCCESS = "success";
const ERROR = "error";
const PROMISE = "promise";

export const showToast = (type, message, duration = 3500, options = {}) => {
    const toastOptions = { duration: duration, ...options };
    switch (type) {
        case SUCCESS:
            return toast.success(message, toastOptions);

        case ERROR:
            return toast.error(message, toastOptions);

        case PROMISE:
            return toast.promise(
                new Promise((resolve, reject) => {
                    resolve();
                }),
                toastOptions
            );
        case type:
            return toast(message, toastOptions);
        default:
            return toast.error(message, toastOptions);
    }
};
