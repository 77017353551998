import Config from "../Config";
import { store } from "../store";
import { Get, Post, Put } from "./api.helper";

export const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

export const GetMe = () => {
    return Get({ url: "/user" });
};

export const ChangePassword = (id = "", bodyData) => {
    return Post({ url: `/auth/change-password/${id}`, body: bodyData });
};

export const GetPaymentToken = (bodyData) => {
    return Post({
        url: "/payment-terminal/payment-transaction",
        body: bodyData
    });
};

export const GetManagers = (params = {}, abortSignal = null) => {
    return Get({ url: "/user/listing", params, abortSignal });
};

export const GetTransactions = (params = {}, abortSignal = null) => {
    return Get({ url: "/payment-terminal/invoices-transactions", params, abortSignal });
};

export const AddManager = (bodyData) => {
    return Post({
        url: "/auth/signup",
        body: bodyData
    });
};

export const ChangeApprovalCode = (id = "", bodyData) => {
    return Post({
        url: `/auth/change-approval-code/${id}`,
        body: bodyData
    });
};

export const ChangeManagerStatus = (id, bodyData) => {
    return Put({
        url: `/auth/${id}`,
        body: bodyData
    });
};

export const ProcessRefund = (bodyData) => {
    return Post({
        url: "/payment-terminal/refund-payment",
        body: bodyData
    });
};

export const GetCSVReport = ({ bodyData = {} }) => {
    const token = store.getState().userAuth.user.Token;
    const url = `${Config.env().API_URL}/payment-terminal/export-csv`;
    return fetch(url, {
        headers: {
            authorization: token,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => Promise.resolve(res.blob()))
        .catch((err) => {
            Promise.reject(err);
        });
};
