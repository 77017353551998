import { Container } from "reactstrap";
import { useSelector } from "react-redux";

import { RoutingTabs } from "../../components/RoutingTabs";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { TopHeader } from "../../components/TopHeader";
import { Footer } from "../../components/Footer";
import { Managers } from "../Managers";
import { Refund } from "../Refund";
import { Transactions } from "../Transactions";
import { Charge } from "../Charge";
import { Roles } from "../../constants";

const Dashboard = ({}) => {
    const Role = useSelector((state) => state?.userAuth?.user?.type);
    const isManager = Role == Roles.Manager;

    const CONFIG = {
        param: [],
        tabsConfig: [
            {
                title: "Charge",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.CHARGE,
                component: <Charge />
            },
            {
                title: "Refund",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.REFUND,
                component: <Refund />
            },
            {
                title: "Transactions",
                id: "3",
                defaultActive: false,
                path: APP_ROUTES.TRANSACTIONS,
                component: <Transactions />
            }
        ]
    };

    if (!isManager) {
        CONFIG.tabsConfig.push({
            title: "Managers",
            id: "4",
            defaultActive: false,
            path: APP_ROUTES.MANAGERS,
            component: <Managers />
        });
    }

    return (
        <>
            <div className={`d-flex align-items-stretch min-vh-10 home-page-con`}>
                <div className="auth-wrap-con  col-12 px-5 pt-5">
                    <Container fluid className="">
                        <TopHeader isProfileMenu={true} />
                        <div className="mt-3">
                            <RoutingTabs
                                config={CONFIG}
                                isPills
                                isTabs={false}
                                redirectionFromRoute={APP_ROUTES.DASHBOARD}
                            />
                        </div>

                        <Footer />
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
