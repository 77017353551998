import React from "react";
import { AutoComplete } from "../AutoComplete";
import { DateRange } from "../DateRange";
import { CustomButton } from "../CustomButton";

const ListingFilterBar = ({
    loading = false,
    isMulti = false,
    startDate = null,
    endDate = null,
    dropDownOptions = [],
    dropDownValue = "",
    dropDownPlaceholder = "",
    onFilterClick = () => {},
    handleDateChange = () => {},
    handleDropDownChange = () => {},
    disableFilterBtn = false
}) => {
    return (
        <div>
            <div className="d-flex flex-wrap">
                <div className="dropdown-container">
                    <AutoComplete
                        placeholder={dropDownPlaceholder}
                        options={dropDownOptions}
                        isMulti={isMulti}
                        value={dropDownValue}
                        onChange={handleDropDownChange}
                    />
                </div>
                <DateRange onChange={handleDateChange} startDate={startDate} endDate={endDate} />
                {/* <CustomButton
                    color="primary"
                    title={<span className="bx bx-filter-alt"></span>}
                    className="filter-btn me-2"
                    loading={loading}
                    onClick={onFilterClick}
                    disabled={disableFilterBtn}
                /> */}
            </div>
        </div>
    );
};

export default ListingFilterBar;
