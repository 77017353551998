import React, { useState } from "react";

import { DataTable } from "../../components/DataTable";
import { ManagerStatus, Roles, SortOrder, PageTitles } from "../../constants";
import { CustomButton } from "../../components/CustomButton";
import { Pagination } from "../../components/Pagination";
import { CustomModal } from "../../components/CustomModal";
import { PageTitle } from "../../components/PageTitle";
import { ChangeApprovalCodeModal } from "../Modals";
import { ChangePassword } from "../Authentication";
import { AddUserModal } from "../Modals";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { AddManager, ChangeManagerStatus, GetManagers } from "../../api/api.service";
import { SwitchInput } from "../../components/Input";
import { OverlayLoader } from "../../components/Loader";
import { showToast } from "../../utils/toastUtils";
import Config from "../../Config";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { Col, Row } from "reactstrap";

const COLUMNS = {
    MANAGERS_NAME: "MANAGERS NAME",
    EMAIL_ADDRESS: "EMAIL ADDRESS",
    ACTION: "ACTIONS",
    STATUS: "STATUS"
};

const Managers = ({}) => {
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isChangePasswordModalActive, setIsChangePasswordModalActive] = useState(false);
    const [isApprovalCodeModalActive, setIsApprovalCodeModalActive] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);

    const handleAddUserModalClose = () => {
        setIsAddUserModalOpen(false);
    };

    const handleChangePasswordModalClose = () => {
        setIsChangePasswordModalActive(false);
    };

    const handleApprovalCodeModalClose = () => {
        setIsApprovalCodeModalActive(false);
    };

    const {
        isFetching,
        data,
        page,
        total,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSetData,
        request,
        handlePageLimit
    } = useLocalPaginatedRequest(
        GetManagers,
        {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            type: Roles.Manager
        },
        [currentSortOrder, currentSortColumn],
        "Q",
        "data",
        "total"
    );

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleInitiChangePassword = (data) => {
        setSelectedManager(data);
        setIsChangePasswordModalActive(!isChangePasswordModalActive);
    };

    const handleInitiChangeApprovalCode = (data) => {
        setSelectedManager(data);
        setIsApprovalCodeModalActive(!isApprovalCodeModalActive);
    };

    const modifyStatusLocally = (obj, status) => {
        let modified = data.map((item) => {
            if (item.id == obj.id) {
                item.status = status;
            }
            return item;
        });
        handleSetData(modified);
    };

    const handleAddUser = async (values) => {
        const { email, name, password, approvalCode } = values;
        let bodyData = {
            email: email,
            password: password,
            name: name,
            authorizationCode: approvalCode
        };
        try {
            setIsLoading(true);
            let res = await AddManager(bodyData);
            handleAddUserModalClose();
            showToast("success", "Manager has been added !");
            request();
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeManagerStatus = async (obj) => {
        let statusToSet =
            obj.status == ManagerStatus.Active ? ManagerStatus.Suspend : ManagerStatus.Active;
        let bodyData = {
            name: obj.name,
            status: statusToSet
        };
        setSelectedManager(obj);

        try {
            setIsLoadingStatus(true);
            let res = await ChangeManagerStatus(obj.id, bodyData);
            modifyStatusLocally(obj, statusToSet);
            showToast("success", res.message);
        } catch (error) {
        } finally {
            setIsLoadingStatus(false);
        }
    };

    return (
        <>
            <div>
                <PageTitle title={PageTitles.REFUND} />

                <div className="home-body p-3 bg-white mt-3 managers-main-con">
                    <Row>
                        <Col sm="12" md="4" lg="4" className="py-2">
                            <ListingHeader
                                onSearchChange={handleSearchChange}
                                searchedText={searchText}
                            />
                        </Col>
                        <Col sm="12" md="5" lg="5"></Col>

                        <Col sm="12" md="3" lg="3" className="py-2">
                            <CustomButton
                                title={"+Add Manager"}
                                outline
                                color={"#00A290"}
                                className="add-user-btn w-100"
                                onClick={() => setIsAddUserModalOpen(true)}
                            />
                        </Col>
                    </Row>

                    <DataTable
                        tableContainerClass=""
                        loading={isFetching}
                        data={data}
                        currentSortOrder={currentSortOrder}
                        currentSortColumn={currentSortColumn}
                        onSortClick={handleSortClick}
                        config={[
                            {
                                title: COLUMNS.MANAGERS_NAME,
                                className: "bold-font sa-cust-name text-truncate ",
                                render: (data) => data?.name || "-"
                            },
                            {
                                title: COLUMNS.EMAIL_ADDRESS,
                                className: "sa-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: (data) => (
                                    <>
                                        <SwitchInput
                                            label={ManagerStatus.getDisplayTextKey(data?.status)}
                                            size="lg"
                                            isChecked={ManagerStatus.Active == data.status}
                                            onClick={() => handleChangeManagerStatus(data)}
                                            labelClass={ManagerStatus.getLabelClass(data?.status)}
                                            switchClass={ManagerStatus.getLabelClass(data?.status)}
                                        />
                                    </>
                                )
                            },
                            {
                                title: COLUMNS.ACTION,
                                className: `sa-action bold-600`,
                                render: (data) => (
                                    <>
                                        <span
                                            className=" pointer managers-listing-btn me-2"
                                            onClick={() => handleInitiChangePassword(data)}
                                        >
                                            Change Password
                                        </span>
                                        <span
                                            className=" pointer managers-listing-btn "
                                            onClick={() => handleInitiChangeApprovalCode(data)}
                                        >
                                            Change Approval Code
                                        </span>
                                    </>
                                )
                            }
                        ]}
                    />
                    <Pagination
                        count={data?.length}
                        onPageClick={handlePageClick}
                        currentPage={page}
                        totalData={total}
                        handleChange={(e) => handlePageLimit(e.target.value)}
                    />
                    <OverlayLoader isLoading={isLoadingStatus} />
                </div>
            </div>

            <CustomModal isOpen={isAddUserModalOpen} toggle={handleAddUserModalClose} size="md">
                <AddUserModal
                    closeModalHandler={handleAddUserModalClose}
                    onSubmit={handleAddUser}
                    isLoading={isLoading}
                />
            </CustomModal>

            <CustomModal
                isOpen={isChangePasswordModalActive}
                toggle={handleChangePasswordModalClose}
                size="md"
            >
                <ChangePassword
                    closeModalHandler={handleChangePasswordModalClose}
                    isManager={selectedManager}
                />
            </CustomModal>

            <CustomModal
                isOpen={isApprovalCodeModalActive}
                toggle={handleApprovalCodeModalClose}
                size="md"
            >
                <ChangeApprovalCodeModal
                    closeModalHandler={handleApprovalCodeModalClose}
                    hasManager={selectedManager}
                />
            </CustomModal>
        </>
    );
};

export default Managers;
