import React from "react";
import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./assets/scss/theme/theme1/theme.scss";
import { APP_ROUTES } from "./helpers/routeHelpers";
import { PageTitles } from "./constants";
import { DefaultLayout } from "./containers";
import { Home } from "./pages/Home";

const App = () => {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path={APP_ROUTES.HOME} component={Home} name={PageTitles.HOME} />

                    <Route path="/" render={(props) => <DefaultLayout {...props} />} />
                    <Redirect to={APP_ROUTES.HOME} />
                </Switch>
            </Router>

            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    className: "",
                    duration: 3000,
                    success: {
                        duration: 3000
                    }
                }}
            />
        </>
    );
};

export default App;
