import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import passwordIcon from "../../assets/images/app/auth-page/lock-on_curved.svg";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { ChangePassword } from "../../api/api.service";
import { SIGN_OUT } from "../../store/actions";
import { initialValues, changePasswordFormSchema } from "./FormConfig";

let SHOW_ICON = "bx bx-sm bx-show theme-primary-color";
let HIDE_ICON = "bx bx-sm bxs-hide theme-primary-color";

const ChangePasswordPage = ({ closeModalHandler, isManager = null }) => {
    let currentUser = useSelector((state) => state.userAuth.user);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        initialValues: initialValues.changePassword,
        validationSchema: changePasswordFormSchema(!!isManager?.id),

        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { oldPassword, confirmNewPassword } = values;
        let bodyData = {
            ...(oldPassword && { CurrentPassword: oldPassword }),
            NewPassword: confirmNewPassword
        };

        let currentUserId = isManager ? isManager.id : currentUser.id;
        try {
            setIsLoading(true);
            let res = await ChangePassword(currentUserId, bodyData);
            closeModalHandler();
            toast.success("Password has been changed successfully !");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="px-5 mx-5">
                <h1 className="auth-heading m-0 p-0 text-center">Change Password</h1>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                    {!isManager?.id && (
                        <div className="mb-4">
                            <TextInput
                                autoComplete={"true"}
                                placeholder="Enter Old Password"
                                name={"oldPassword"}
                                type={!passwordFieldType ? "password" : "text"}
                                className="hide-default-icon"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.oldPassword}
                                iconBeforeText={passwordIcon}
                                iconClickHandler={passwordFieldChangeHandler}
                                icon={!passwordFieldType ? SHOW_ICON : HIDE_ICON}
                            />
                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                <span className="error">{formik.errors.oldPassword}</span>
                            ) : null}
                        </div>
                    )}

                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Enter New Password"
                            name={"newPassword"}
                            type={!passwordFieldType ? "password" : "text"}
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                            iconBeforeText={passwordIcon}
                            iconClickHandler={passwordFieldChangeHandler}
                            icon={!passwordFieldType ? SHOW_ICON : HIDE_ICON}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <span className="error">{formik.errors.newPassword}</span>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <TextInput
                            placeholder="Confirm New Password"
                            type={!passwordFieldType ? "password" : "text"}
                            className="hide-default-icon"
                            name="confirmNewPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmNewPassword}
                            iconBeforeText={passwordIcon}
                            iconClickHandler={passwordFieldChangeHandler}
                            icon={!passwordFieldType ? SHOW_ICON : HIDE_ICON}
                        />
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                            <span className="error">{formik.errors.confirmNewPassword}</span>
                        ) : null}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="w-100 mt-4 me-3 back-btn" onClick={closeModalHandler}>
                            Back
                        </span>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title="Save"
                            className="w-100 mt-4 ms-3"
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch({ type: SIGN_OUT })
});

export default connect(null, mapDispatchToProps)(withRouter(ChangePasswordPage));
