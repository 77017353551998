import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import toast from "react-hot-toast";

import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "../../store/actions";
import PageTitles from "../../constants/PageTitles";
import userIcon from "../../assets/images/app/auth-page/user-1_curved.svg";
import passwordIcon from "../../assets/images/app/auth-page/lock-on_curved.svg";
import { initialValues, loginFormSchema } from "./FormConfig";

let SHOW_ICON = "bx bx-sm bx-show theme-primary-color";
let HIDE_ICON = "bx bx-sm bxs-hide theme-primary-color";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        initialValues: initialValues.login,
        validationSchema: loginFormSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    email,
                    password
                })
            );
            toast.success("Login successfully !");
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    // proceed button handling
    const handleProceedBtn = () => {
        history.push(APP_ROUTES.DASHBOARD);
    };

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <div className="px-5 mx-5 py-4">
                <h1 className="auth-heading m-0 p-0 text-center">Authentication</h1>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Enter email"
                            name={"email"}
                            type="email"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            iconBeforeText={userIcon}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <span className="error">{formik.errors.email}</span>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <TextInput
                            placeholder="Enter password"
                            type={!passwordFieldType ? "password" : "text"}
                            className="hide-default-icon"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            iconBeforeText={passwordIcon}
                            iconClickHandler={passwordFieldChangeHandler}
                            icon={!passwordFieldType ? SHOW_ICON : HIDE_ICON}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <span className="error">{formik.errors.password}</span>
                        ) : null}
                    </div>

                    <CustomButton
                        loading={isLoading}
                        type="submit"
                        color="primary"
                        title="Proceed"
                        className="w-100 mt-4"
                    />
                </form>
            </div>
        </>
    );
};

export default withRouter(Login);
