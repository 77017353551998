import React from "react";

const FormikErrorText = ({ formikInstance, fieldName }) => {
    return (
        <>
            {formikInstance.touched[fieldName] && formikInstance.errors[fieldName] ? (
                <span className="error">{formikInstance.errors[fieldName]}</span>
            ) : null}
        </>
    );
};

export default FormikErrorText;
