import { useEffect, useRef, memo } from "react";

import Config from "../../Config";

const PaymentForm = memo(({ token = "" }) => {
    let formRef = useRef("");

    useEffect(() => {
        if (token && formRef.current) {
            formRef.current.click();
        }
    }, [token, formRef]);

    return (
        <form
            hidden
            method="post"
            action={Config.env().AUTHORIZE_NET_URL}
            id="formAuthorizeNetTestPage"
            name="formAuthorizeNetTestPage"
        >
            <input type="hidden" name="token" value={token} />
            <button id="btnContinue" type="submit" ref={formRef}></button>
        </form>
    );
});

export default PaymentForm;
